<template>
    <div>
        <h3 class="mb-5">{{ $t("transactions.codes.title") }}
            <v-btn
                :loading="loading"
                class="mx-2"
                color="secondary"
                fab
                small
                @click="dialog = true"
            >
                <v-icon>
                    mdi-wallet-plus
                </v-icon>
            </v-btn>
        </h3>

        <v-simple-table v-if="transactionCodes.length > 0 && !loading">
            <template v-slot:default>
                <thead>
                <tr>
                    <th class="text-left">
                        {{ $t("transactions.codes.code") }}
                    </th>
                    <th class="text-left">
                        {{ $t("transactions.codes.amount") }}
                    </th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                <tr
                    v-for="item in transactionCodes"
                    :key="item.uuid"
                >
                    <td>
                        <DynamicElement :uuid="item.code" type="accounting_code"></DynamicElement>
                    </td>
                    <td>{{ item.amount }}</td>
                    <td>
                        <v-tooltip v-if="!item.children" bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    class="ml-2"
                                    color="error"
                                    icon
                                    small
                                    v-bind="attrs"
                                    @click="deleteElement(item.uuid)"
                                    v-on="on"
                                >
                                    <v-icon>
                                        mdi-delete
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t("transactions.codes.delete") }}</span>
                        </v-tooltip>
                    </td>
                </tr>
                </tbody>
            </template>
        </v-simple-table>

        <v-skeleton-loader v-else-if="loading" type="card"></v-skeleton-loader>

        <v-card v-else class="mt-5" outlined>
            <v-card-title>{{ $t("transactions.codes.empty") }}</v-card-title>
        </v-card>

        <v-dialog
            v-model="dialog"
            width="500"
        >
            <v-form @submit.prevent="formSubmit">
                <v-card :disabled="loading" :loading="loading">
                    <v-card-title class="text-h5 grey lighten-2 mb-3">
                        {{ $t("transactions.codes.new") }}
                    </v-card-title>

                    <v-card-text>
                        <v-autocomplete
                            v-model="code"
                            :disabled="loading"
                            :error-messages="codeErrors"
                            :item-text="el => (el.code + ' - ' + el.description)"
                            :items="flattenedCodes"
                            :label="$t('transactions.codes.code')"
                            :loading="loading"
                            :no-data-text="$t('no_data')"
                            item-value="uuid"
                            outlined
                            @blur="$v.code.$touch()"
                            @input="$v.code.$touch()"
                        >
                        </v-autocomplete>

                        <v-text-field
                            v-model="amount"
                            :disabled="loading"
                            :error-messages="amountErrors"
                            :label="$t('transactions.codes.amount')"
                            :suffix="currency"
                            outlined
                            @blur="$v.amount.$touch()"
                            @input="$v.amount.$touch()"
                        ></v-text-field>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="error"
                            text
                            @click="closeDialog"
                        >
                            {{ $t("close") }}
                        </v-btn>
                        <v-btn
                            color="secondary"
                            text
                            type="submit"
                        >
                            {{ $t("transactions.codes.save") }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
    </div>
</template>

<script>
import Accounting from "@/helpers/Accounting";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import DynamicElement from "@/components/DynamicElement.vue";
import Utils from "@/helpers/Utils";

export default {
    name: "AccountingCodes",
    components: {DynamicElement},
    props: ["transaction", "currency", "totalAmount"],
    data() {
        return {
            loading: false,
            selected: [],
            codes: [],
            transactionCodes: [],
            dialog: false,
            amount: null,
            code: null
        };
    },
    async mounted() {
        await this.fetchElements();
    },
    methods: {
        async fetchElements() {
            this.loading = true;
            let respCodes = await Accounting.get("/accounting/codes")
                .catch(() => {

                });

            if (respCodes !== undefined) {
                this.codes = respCodes.data.codes;
            }

            let respCat = await Accounting.get("/transactions/" + this.transaction + "/codes")
                .catch(() => {
                });

            if (respCat !== undefined) {
                this.transactionCodes = respCat.data.codes;
            }

            this.amount = this.updateAmountLeft();

            this.loading = false;
        },
        flattenArr(arr) {
            let codes = [];

            for (const code of arr) {
                codes.push(code);
                if (code["children"] !== undefined && code["children"].length > 0) {
                    const children = this.flattenArr(code["children"]);
                    for (const child of children) {
                        codes.push(child);
                    }
                }
            }

            return codes;
        },
        updateAmountLeft() {
            let amount = 0;
            for (const code of this.transactionCodes) {
                amount += code.amount;
            }

            if ((Math.abs(this.totalAmount) - amount) < 0) {
                return 0;
            }

            return Math.abs(this.totalAmount) - amount;
        },
        closeDialog() {
            this.dialog = false;
            this.code = null;
            this.amount = this.updateAmountLeft();
        },
        formSubmit() {
            this.$v.$touch();
            if (this.$v.$invalid || this.amountErrors.length > 0) {
                return;
            }

            this.$store.commit("SET_ALERTS", []);

            Accounting.post("/transactions/" + this.transaction + "/codes", {
                amount: this.amount,
                code: this.code
            }).then(() => {
                this.loading = false;
                this.closeDialog();
                this.fetchElements();
            }).catch(error => {
                if (error.response) {
                    let errors = [];
                    for (const errorElement of error.response.data.errors) {
                        errors.push({
                            type: "error",
                            text: errorElement
                        });
                    }
                    this.$store.commit("SET_ALERTS", errors);
                } else if (error.request) {
                    this.$store.commit("SET_ALERTS", [{
                        type: "error",
                        text: this.$t("api.no_response")
                    }]);
                } else {
                    this.$store.commit("SET_ALERTS", [{
                        type: "error",
                        text: error.message
                    }]);
                }
                this.loading = false;
            });
        },
        deleteElement(uuid) {
            this.$swal({
                title: this.$t("transactions.codes.delete_title"),
                text: this.$t("transactions.codes.delete_text"),
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: this.$t("transactions.codes.delete_yes"),
                cancelButtonText: this.$t("transactions.codes.delete_no"),
                confirmButtonColor: Utils.getColor(this, "error"),
                cancelButtonColor: Utils.getColor(this, "primary")
            }).then(result => {
                if (result.isConfirmed) {
                    this.loading = true;
                    this.$store.commit("SET_ALERTS", []);
                    Accounting.delete("/transactions/" + this.transaction + "/codes/" + uuid)
                        .then(() => {
                            this.$store.commit("SET_ALERTS", [{
                                type: "success",
                                text: this.$t("transaction.codes.deleted")
                            }]);
                            this.loading = false;
                            this.fetchElements();
                        })
                        .catch(error => {
                            if (error.response) {
                                let errors = [];
                                for (const errorElement of error.response.data.errors) {
                                    errors.push({
                                        type: "error",
                                        text: errorElement
                                    });
                                }
                                this.$store.commit("SET_ALERTS", errors);
                            } else if (error.request) {
                                this.$store.commit("SET_ALERTS", [{
                                    type: "error",
                                    text: this.$t("api.no_response")
                                }]);
                            } else {
                                this.$store.commit("SET_ALERTS", [{
                                    type: "error",
                                    text: error.message
                                }]);
                            }
                            this.loading = false;
                        });
                }
            });
        }
    },
    watch: {
        totalAmount() {
            this.amount = this.updateAmountLeft();
        }
    },
    mixins: [validationMixin],
    validations: {
        code: {required},
        amount: {
            required
        }
    },
    computed: {
        flattenedCodes() {
            return this.flattenArr(this.codes);
        },
        codeErrors() {
            const errors = [];
            if (!this.$v.code.$dirty) return errors;
            !this.$v.code.required && errors.push(this.$t("transactions.codes.code_required"));
            return errors;
        },
        amountErrors() {
            const errors = [];
            if (!this.$v.amount.$dirty) return errors;
            !this.$v.amount.required && errors.push(this.$t("transactions.codes.amount_transaction"));
            this.amount <= 0 && errors.push(this.$t("transactions.codes.min_value"));
            this.amount > this.updateAmountLeft() && errors.push(this.$t("transactions.codes.max_value"));
            return errors;
        }
    }
};
</script>

<style scoped>

</style>