<template>
    <div class="ml-10 mr-10">
        <!--        <TabNav-->
        <!--            v-if="this.$route.params.uuid !== 'new'"-->
        <!--            :elements="[-->
        <!--                {text: this.$t('accounts.edit'),to: '/accounts/' + this.$route.params.uuid,active: true,},-->
        <!--                {text: this.$t('menu.transactions'),to: '/accounts/' + this.$route.params.uuid + '/transactions'}-->
        <!--            ]"-->
        <!--        ></TabNav>-->

        <TitleBar
            v-if="this.$route.params.id === 'new'"
            :disabled="loading"
            :loading="loading"
            :text="this.$t('transactions.new')"
        ></TitleBar>
        <TitleBar
            v-else
            :disabled="loading"
            :loading="loading"
            :menu="[{text: this.$t('transactions.delete'), callback: deleteElement, class: 'red--text'}]"
            :text="this.$t('transactions.edit')"
        ></TitleBar>

        <Tags v-if="this.$route.params.id !== 'new'" :element="$route.params.id"></Tags>

        <v-form @submit.prevent="formSubmit">
            <v-alert
                v-if="has_connections"
                border="left"
                type="info"
            >
                {{ $t("transactions.connection_warning") }}
            </v-alert>

            <v-text-field
                v-model="id"
                :disabled="loading"
                :error-messages="idErrors"
                :label="$t('transactions.id')"
                outlined
                @blur="$v.id.$touch()"
                @input="$v.id.$touch()"
            ></v-text-field>

            <DatePicker
                v-model="date"
                :disabled="loading"
                :error-messages="dateErrors"
                :label="$t('transactions.date')"
                @blur="$v.date.$touch()"
                @input="$v.date.$touch()"
            ></DatePicker>

            <v-text-field
                v-model="statement"
                :disabled="loading"
                :error-messages="statementErrors"
                :label="$t('transactions.statement')"
                outlined
                @blur="$v.statement.$touch()"
                @input="$v.statement.$touch()"
            ></v-text-field>

            <v-text-field
                v-model="amount"
                :disabled="loading"
                :error-messages="amountErrors"
                :label="$t('transactions.amount')"
                :suffix="currency"
                outlined
                @blur="$v.amount.$touch()"
                @input="$v.amount.$touch()"
            ></v-text-field>

            <v-btn
                :loading="loading"
                color="secondary"
                elevation="2"
                type="submit"
            >{{ $route.params.id === "new" ? $t("transactions.add") : $t("transactions.update") }}
            </v-btn>
        </v-form>

        <div v-if='$route.params.id !== "new"'>
            <v-divider class="mt-10 mb-5"></v-divider>

            <h3 class="mb-5">{{ $t("transactions.link") }}</h3>
            <TransactionLink v-if="!loading" :link_status="link_status"
                             :linked_to="linked_to" :uuid="$route.params.id"></TransactionLink>
            <v-skeleton-loader v-else-if="loading" type="card"></v-skeleton-loader>

            <v-divider class="mt-10 mb-5"></v-divider>
            <AccountingCodes :currency="currency" :total-amount="amount"
                             :transaction="$route.params.id"></AccountingCodes>
        </div>
    </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import TitleBar from "@/components/TitleBar.vue";
import Utils from "@/helpers/Utils";
import Accounting from "@/helpers/Accounting";
import Tags from "@/components/Tags.vue";
import DatePicker from "@/components/DatePicker.vue";
import AccountingCodes from "@/views/Accounts/components/AccountingCodes.vue";
import TransactionLink from "@/components/TransactionLink.vue";

export default {
    name: "Details",
    components: {TransactionLink, AccountingCodes, DatePicker, Tags, TitleBar},
    async mounted() {
        this.$store.commit("SET_BREADCRUMBS", [
            {
                text: this.$t("menu.home"),
                to: "/",
                exact: true
            },
            {
                text: this.$t("menu.accounts"),
                to: "/accounts",
                exact: true
            },
            {
                text: this.$t("menu.transactions"),
                to: "/accounts/" + this.$route.params.uuid + "/transactions",
                exact: true
            },
            {
                text: this.$route.params.id === "new" ? this.$t("transactions.new") : this.$t("transactions.edit"),
                to: "/accounts/" + this.$route.params.uuid + "/transactions/" + this.$route.params.id,
                exact: false
            }
        ]);

        this.loading = true;
        // Count connected bank accounts
        let connections = await Accounting.get("/accounts/" + this.$route.params.uuid + "/connect")
            .catch(() => {
            });

        if (connections) {
            this.has_connections = connections.data.total > 0;
        }
        // Fetch account details
        let account = await Accounting.get("/accounts/" + this.$route.params.uuid)
            .catch(() => {
            });

        if (account) {
            this.currency = account.data.currency;
        }
        this.loading = false;

        if (this.$route.params.id !== "new") {
            this.loading = true;
            Accounting.get("/transactions/" + this.$route.params.id)
                .then(response => {
                    const data = response.data;

                    this.date = data.transaction_date;
                    this.statement = data.statement_descriptor;
                    this.amount = data.amount;
                    this.id = data.transaction_id;
                    this.linked_to = data.linked_to;
                    this.link_status = data.link_status;
                    this.loading = false;
                }).catch(error => {
                if (error.response) {
                    let errors = [];
                    for (const errorElement of error.response.data.errors) {
                        errors.push({
                            type: "error",
                            text: errorElement
                        });
                    }
                    this.$store.commit("SET_ALERTS", errors);
                } else if (error.request) {
                    this.$store.commit("SET_ALERTS", [{
                        type: "error",
                        text: this.$t("api.no_response")
                    }]);
                } else {
                    this.$store.commit("SET_ALERTS", [{
                        type: "error",
                        text: error.message
                    }]);
                }
                this.loading = false;
            });
        }
    },
    data: () => {
        return {
            loading: false,
            has_connections: false,

            date: null,
            statement: null,
            amount: null,
            id: null,

            currency: null,
            link_status: null,
            linked_to: null
        };
    },
    methods: {
        deleteElement() {
            this.$swal({
                title: this.$t("transactions.delete_title"),
                text: this.$t("transactions.delete_text"),
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: this.$t("transactions.delete_yes"),
                cancelButtonText: this.$t("transactions.delete_no"),
                confirmButtonColor: Utils.getColor(this, "error"),
                cancelButtonColor: Utils.getColor(this, "primary")
            }).then(result => {
                if (result.isConfirmed) {
                    this.loading = true;
                    this.$store.commit("SET_ALERTS", []);
                    Accounting.delete("/transactions/" + this.$route.params.id)
                        .then(() => {
                            this.$store.commit("SET_ALERTS", [{
                                type: "success",
                                text: this.$t("transactions.deleted")
                            }]);
                            this.loading = false;
                            this.$router.push("/accounts/" + this.$route.params.uuid + "/transactions");
                        })
                        .catch(error => {
                            if (error.response) {
                                let errors = [];
                                for (const errorElement of error.response.data.errors) {
                                    errors.push({
                                        type: "error",
                                        text: errorElement
                                    });
                                }
                                this.$store.commit("SET_ALERTS", errors);
                            } else if (error.request) {
                                this.$store.commit("SET_ALERTS", [{
                                    type: "error",
                                    text: this.$t("api.no_response")
                                }]);
                            } else {
                                this.$store.commit("SET_ALERTS", [{
                                    type: "error",
                                    text: error.message
                                }]);
                            }
                            this.loading = false;
                        });
                }
            });
        },
        formSubmit() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.loading = true;

                let url = "/transactions";

                if (this.$route.params.id !== "new") {
                    url = url + "/" + this.$route.params.id;
                }

                this.$store.commit("SET_ALERTS", []);

                Accounting.post(url, {
                    account: this.$route.params.uuid,
                    amount: this.amount,
                    statement_descriptor: this.statement,
                    transaction_date: this.date,
                    transaction_id: this.id
                }).then(response => {
                    this.loading = false;
                    if (this.$route.params.id === "new") {
                        this.$store.commit("SET_ALERTS", [{
                            type: "success",
                            text: this.$t("transactions.added")
                        }]);
                        this.$router.push("/accounts/" + this.$route.params.uuid + "/transactions/" + response.data.uuid);
                    } else {
                        this.$store.commit("SET_ALERTS", [{
                            type: "success",
                            text: this.$t("transactions.updated")
                        }]);
                    }

                }).catch(error => {
                    if (error.response) {
                        let errors = [];
                        for (const errorElement of error.response.data.errors) {
                            errors.push({
                                type: "error",
                                text: errorElement
                            });
                        }
                        this.$store.commit("SET_ALERTS", errors);
                    } else if (error.request) {
                        this.$store.commit("SET_ALERTS", [{
                            type: "error",
                            text: this.$t("api.no_response")
                        }]);
                    } else {
                        this.$store.commit("SET_ALERTS", [{
                            type: "error",
                            text: error.message
                        }]);
                    }
                    this.loading = false;
                });

            }
        }
    },
    mixins: [validationMixin],
    validations: {
        date: {required},
        statement: {required},
        amount: {required},
        id: {required}
    },
    computed: {
        dateErrors() {
            const errors = [];
            if (!this.$v.date.$dirty) return errors;
            !this.$v.date.required && errors.push(this.$t("transactions.date_required"));
            return errors;
        },
        statementErrors() {
            const errors = [];
            if (!this.$v.statement.$dirty) return errors;
            !this.$v.statement.required && errors.push(this.$t("transactions.statement_required"));
            return errors;
        },
        amountErrors() {
            const errors = [];
            if (!this.$v.amount.$dirty) return errors;
            !this.$v.amount.required && errors.push(this.$t("transactions.amount_required"));
            return errors;
        },
        idErrors() {
            const errors = [];
            if (!this.$v.id.$dirty) return errors;
            !this.$v.id.required && errors.push(this.$t("transactions.id_required"));
            return errors;
        }
    }
};
</script>

<style>

</style>